.flex{
  display: flex;
  flex-wrap: wrap;
}
.align-center{
  align-items: center;
}
.justify-center{
  justify-content: center;
}
.justify-around{
  justify-content: space-around;
}
.justify-between{
  justify-content: space-between;
}
.text-red{
  color: red;
}
.text-green{
  color: green;
}
.text-center{
  text-align: center;
}
.w100{
  width: 100%;
}
.m1-0{
  margin: 1em 0;
}
.p05{
  padding: 0.5em;
}
.span010{
  margin:  0 10px;
}
.sticky{
  position: sticky;
  z-index: 100;
  left: 0;
  background: white;
  box-shadow: 5px 2px 5px grey;
  /* border-right: 2px solid black; */
}
.height-16{
  height: 16px;
}
.fixed{
  position: fixed;
}