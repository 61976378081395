
.card{
    border: 1px solid rgba(242,243,244,0.75);
    border-radius: 0.15em;
    /* z-index: 10; */
    display: inline-block;
    width: 1em;
    height: 1.4em;
    text-align: center;
    margin: 0 10px
}
.yellow{
    background: #ffed00;
}
.red{
    background: #e60028;
    
}