.game-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60px;
}
.game-score{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
    text-align: center;
}
.margin1-0{
    margin: 1em 0
}